import { render, staticRenderFns } from "./coa.vue?vue&type=template&id=3123c4cc&scoped=true&"
import script from "./coa.vue?vue&type=script&lang=js&"
export * from "./coa.vue?vue&type=script&lang=js&"
import style0 from "./coa.vue?vue&type=style&index=0&id=3123c4cc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3123c4cc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsDivider } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsItem } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VBreadcrumbs,VBreadcrumbsDivider,VBreadcrumbsItem,VBtn,VDivider,VSwitch})
