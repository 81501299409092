<template>
  <div class="container">
    <v-breadcrumbs class="d-print-none">
      <v-breadcrumbs-item :exact="true" :to="{ name: 'home', params: {} }">
        Home
      </v-breadcrumbs-item>
      <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
      <v-breadcrumbs-item disabled> CoA </v-breadcrumbs-item>
    </v-breadcrumbs>

    <div class="d-print-none pb-5">
      <div class="d-flex justify-space-between">
        <v-btn @click="goBack"> Back </v-btn>
        <v-switch
            hide-details
            dense
            class="my-auto mt-2"
            v-model="condensePrint"
            :value="true"
            label="Condensed"
          />
        <div class="d-flex flex-column justify-center">
          <v-btn class="mx-2" @click="print()">
            {{ $vuetify.lang.t("$vuetify.printSetting.print") }}
          </v-btn>
        </div>
      </div>
      <v-divider />
    </div>

    <table v-if="loaded" style="width: 100%">
      <thead>
        <tr>
          <th>
            <company-letter-head class="pb-5" />
            <v-divider />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <div
              class="
                mx-5
                d-flex
                flex-column flex-sm-row
                print-flex-row
                flex-space-between
              "
            >
              <div class="mr-auto">
                <table class="cell-spacing">
                  <tr>
                    <td class="font-weight-bold">Document No.</td>
                    <td>{{ labResult.averoutN }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">Program</td>
                    <td>{{ labResult.program }}</td>
                  </tr>
                  <tr v-if="labResult.sampleName">
                    <td class="font-weight-bold">Sample Name</td>
                    <td>
                      {{ labResult.sampleName }}
                    </td>
                  </tr>
                </table>
              </div>
              <div class="ml-0 ml-md-auto">
                <table class="cell-spacing">
                  <tr>
                    <td class="font-weight-bold">Test Time</td>
                    <td>{{ labResult.testTime | formatDateYear }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">Technician</td>
                    <td>{{ labResult.technician }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">Final</td>
                    <td>{{ labResult.finalTest ? 'Yes':'No'}}</td>
                  </tr>
                </table>
              </div>
            </div>

            <div class="mx-6 mt-8">
              <table :class="`table ${condensePrint ? 'table-sm' : ''}`">
                <thead class="font-weight-bold">
                  <tr>
                    <td></td>
                    <td>Symbol</td>
                    <td>Name</td>
                    <td>Value</td>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(row, index) in labResult.elements"
                    :key="row.id"
                    align-content="center"
                  >
                    <td>{{ index + 1 }}.</td>
                    <td>
                      {{ row.element.symbol }}
                    </td>
                    <td>
                      {{ row.element.elementName }}
                    </td>
                    <td>
                      {{(row.lessThan ? '<':'')}} {{ row.compositionPercent }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="4"></td>
        </tr>
        <tr>
          <td>
            <div style="height: 20px" />
            <v-divider />
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td>
            <div class="d-flex justify-space-between">
              <span v-if="isPanya">
                บันทึก : จัดเก็บในแฟ้มเรียงตามลำดับเลขที่, ระยะเวลาจัดเก็บ 2 ปี,
                วิธีการทำลายย่อย, ผู้รับผิดชอบ MK
              </span>
              <span v-if="isPanya">
                FM-MK-01-08 Rev.00 Eff. Date : 04/04/22
              </span>

              <span v-if="!isPanya">
                แบบฟอร์มใบแจ้งน้ำหนักตะกั่ว F-09-11 ฉบับที่ 03 วันที่บังคับใช้
                14 มิ.ย. 65
              </span>
            </div>
            <div class="d-flex justify-end font-weight-light text-caption">
              {{
                $vuetify.lang.t(
                  "$vuetify.packaging.printOn",
                  $options.filters.formatDateYear(printDate)
                )
              }}
            </div>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import { getInventoryLabResult } from "@/services/inventoryLabResult";

import { mapActions } from "vuex";
import moment from "moment";
const CompanyLetterHead = () =>
  import("@/components/common/CompanyLetterHead.vue");

export default {
  data() {
    return {
      companyName: process.env.VUE_APP_COMPANY_NAME,
      id: "",
      labResult: {},
      loaded: false,
      productInfo: null,
      printDate: moment(),
      condensePrint: false,
    };
  },
  components: { CompanyLetterHead },
  computed: {
    isPanya() {
      return this.companyName.startsWith("Panyaraksa");
    },
  },
  methods: {
    ...mapActions("messages", [
      "addMessage",
      "addErrorMessage",
      "addSuccessMessage",
    ]),
    ...mapActions("navigation", ["hideDrawer"]),
    ...mapActions("categoryStore", ["fetchCategory"]),

    goBack() {
      this.$router.go(-1);
    },
    async print() {
      await this.hideDrawer();
      window.print();
    },
    setInstance() {
      getInventoryLabResult(this.id)
        .then((response) => {
          this.labResult = response.data;

          this.labResult.elements = this.labResult.elements.sort( (a, b) => b.compositionPercent - a.compositionPercent );

          this.loadProductInfo(this.labResult.productId);

          this.loaded = true;
        })
        .catch(() => {
          this.addErrorMessage("Error loading lab result");
          this.loaded = true;
        });
    },
    async loadProductInfo(productId) {
      if (productId) {
        return await this.fetchCategory(productId).then((resp) => {
          this.productInfo = resp;

          return this.productInfo;
        });
      } else {
        return null;
      }
    },
  },
  async created() {
    this.id = this.$route.params.id;

    this.setInstance();
  },
};
</script>
<style scoped>
table.qr-info > tbody > tr > td:nth-child(1) {
  padding-right: 5px;
}

div.break-before {
  page-break-before: always;
}

div.dont-break {
  break-inside: avoid;
}

.cell-spacing {
  border-collapse: separate;
  border-spacing: 10px;
}

div.footer-spacing {
  height: 50px;
}

@media print {
  @page {
    size: A4;
    margin: 10mm 10mm 10mm 10mm;
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
    color-adjust: exact !important; /*Firefox*/
  }

  .mb-10 {
    margin-bottom: 5px !important;
  }

  .print-img {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
    color-adjust: exact !important; /*Firefox*/
  }

  div.print-flex-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    justify-content: space-between !important;
  }
}
</style>
